import { getHeadersForTable } from "@/utils/table"
import {
  TABLE_NAMES,
  ISSUE_TYPES
} from "@/constants"
export default {
  name: "IssueFormTemplates",
  data: () => ({
    issueFormTemplates            : [],
    issueFormTemplatesToBeAdded   : [],
    showAddIssueFormTemplateDialog: false
  }),
  emits: ["addIssueFormTemplates"],
  props: {
    pFieldsMap                 : Object,
    pFormTemplates             : Array,
    pIssueFormTemplates        : Array,
    pFormTemplateConfigurations: Array,
    pIsAddingIssueFormTemplates: Boolean,
    pIsIssueFormTemplatesAdded : Boolean
  },
  computed: {
    headersForIssueFormTemplatesTable() {
      return getHeadersForTable(TABLE_NAMES.ISSUE_FORM_TEMPLATE_FOR_ISSUE_TYPE, this.$t.bind(this))
    },
    headersForAddIssueFormTemplatesTable() {
      return getHeadersForTable(TABLE_NAMES.ISSUE_FORM_TEMPLATE_ADD_FOR_ISSUE_TYPE, this.$t.bind(this))
    },
    sortedIssueFormTemplates() {
      let sortedIssueFormTemplates = []
      if (this.pIssueFormTemplates.length) {
        sortedIssueFormTemplates = [...this.pIssueFormTemplates]
        sortedIssueFormTemplates.sort((issueFormTemplate1, issueFormTemplate2) =>
          issueFormTemplate1.sortingOrder - issueFormTemplate2.sortingOrder)
      }
      return sortedIssueFormTemplates
    },
    itemsForIssueFormTemplatesTable() {
      let itemsForIssueFormTemplatesTable = []
      if (this.sortedIssueFormTemplates.length) {
        itemsForIssueFormTemplatesTable = this.sortedIssueFormTemplates.map(issueFormTemplate => {
          const fieldNames = this.getIssueFormTemplateFieldNames(issueFormTemplate.formTemplateId)
          return {
            id  : issueFormTemplate.id,
            name: this.pFormTemplates.find(
              formTemplate => formTemplate.id === issueFormTemplate.formTemplateId)?.name,
            fields: fieldNames?.length ? fieldNames.join(", ") : this.$t("741")
          }
        })
      }
      return itemsForIssueFormTemplatesTable
    },
    issueFormTemplateIds() {
      return this.pIssueFormTemplates.map(issueField => issueField.formTemplateId)
    },
    itemsForAddIssueFormTemplatesTable() {
      return this.pFormTemplates.filter(formTemplate =>
        !this.issueFormTemplateIds.includes(formTemplate.id) &&
        !formTemplate.reportForm
      ).map(formTemplate => {
        return {
          id    : formTemplate.id,
          name  : formTemplate.name,
          fields: this.getIssueFormTemplateFieldNames(formTemplate.id)?.length ?
            this.getIssueFormTemplateFieldNames(formTemplate.id).join(", ") :
            this.$t("741")
        }
      })
    },
    issueFormTemplateFieldsMap() {
      const issueFormTemplateFieldsMap = new Map()
      for (const configuration of this.pFormTemplateConfigurations) {
        let fields = [this.pFieldsMap[configuration.fieldId]]
        if (issueFormTemplateFieldsMap.has(configuration.formTemplateId)) {
          fields = [...fields, ...issueFormTemplateFieldsMap.get(configuration.formTemplateId)]
        }
        issueFormTemplateFieldsMap.set(configuration.formTemplateId, fields)
      }
      return issueFormTemplateFieldsMap
    },
    selectedIssueFormTemplate() {
      if (this.$route.params.id) {
        return this.pIssueFormTemplates.find(
          issueFormTemplate => issueFormTemplate.id === +this.$route.params.id
        )
      }
    }
  },
  methods: {
    handleClickOnRow(item) {
      const isCurrentRouteIssueFieldsAndForms   = this.$route.name === "issue-fields-and-forms"
      const isDifferentIssueFormTemplateClicked = this.$route.name === "issue-form-template" && this.selectedIssueFormTemplate.id !== item.id
      if (isCurrentRouteIssueFieldsAndForms || isDifferentIssueFormTemplateClicked) {
        this.$router.push({ name: "issue-form-template", params: { id: item.id } })
      }
    },
    getIssueFormTemplateFieldNames(formTemplateId) {
      return this.issueFormTemplateFieldsMap
        ?.get(formTemplateId)
        ?.map(field => field?.systemName)
    },
    cancelAddIssueFormTemplates() {
      this.showAddIssueFormTemplateDialog = false
    },
    addFormTemplatesToIssue() {
      this.$emit("addIssueFormTemplates", this.issueFormTemplatesToBeAdded.map(formTemplate => ({
        formTemplateId: formTemplate.id,
        typeId        : ISSUE_TYPES[0].id
      })))
    }
  },
  watch: {
    pIsIssueFormTemplatesAdded: {
      handler: function(value) {
        if (value) {
          this.showAddIssueFormTemplateDialog = false
        }
      }
    },
    showAddIssueFormTemplateDialog: {
      handler: function(value) {
        if (!value) {
          this.issueFormTemplatesToBeAdded = []
        }
      }
    }
  }
}