import ThemisIssueFields from "@/components/issue-fields"
import ThemisIssueFormTemplates from "@/components/issue-form-templates"
import { mapGetters, mapActions } from "vuex"
import { ANCHOR_LINKS } from "@/constants"
export default {
  name      : "IssueFieldsAndForms",
  components: {
    ThemisIssueFields,
    ThemisIssueFormTemplates
  },
  data() {
    return {
      selectedTab    : null,
      fieldSystemName: null
    }
  },
  methods: {
    ...mapActions({
      addIssueFields       : "issueFields/addIssueFields",
      notify               : "shared/notify",
      updateIssueField     : "issueFields/updateIssueField",
      addIssueFormTemplates: "issueFormTemplates/addIssueFormTemplates"
    }),
    handleTabClickEvent(hash) {
      this.selectedTab = this.tabs[hash]
      if (this.$route.hash !== hash) {
        this.$router.push({
          name: "issue-fields-and-forms",
          hash
        }
        )
      }
    },
    handleUpdateIssueField(value) {
      this.fieldSystemName = value.fieldSystemName
      this.updateIssueField({
        id          : value.id,
        sortingOrder: value.sortingOrder
      })
    }
  },
  computed: {
    ...mapGetters({
      fieldsV2                  : "fields/fieldsV2",
      optionLists               : "optionLists/optionLists",
      issueFields               : "issueFields/issueFields",
      isAddingIssueFields       : "issueFields/isAddingIssueFields",
      isIssueFieldsAdded        : "issueFields/isIssueFieldsAdded",
      isSortingOrderUpdated     : "issueFields/isSortingOrderUpdated",
      isIssueFormsEnabled       : "configurations/isIssueFormsEnabled",
      issueFormTemplates        : "issueFormTemplates/issueFormTemplates",
      formTemplates             : "formTemplates/formTemplates",
      formTemplateConfigurations: "formTemplateConfigurations/formTemplateConfigurations",
      isAddingIssueFormTemplates: "issueFormTemplates/isAddingIssueFormTemplates",
      isIssueFormTemplatesAdded : "issueFormTemplates/isIssueFormTemplatesAdded"
    }),
    tabs() {
      return {
        [ANCHOR_LINKS.ISSUE_FIELDS]: 0,
        [ANCHOR_LINKS.FORMS]       : 1
      }
    },
    fieldsMap() {
      const fieldsMap = new Object()
      for (const field of this.fieldsV2) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    }
  },
  watch: {
    "$route.hash": {
      immediate: true,
      handler  : function(hash) {
        if (hash) {
          const regex     = /^#(forms|fields)_(\d+)$/
          const hashMatch = hash.match(regex)
          if (hashMatch) {
            const id         = hashMatch[2]
            const anchorName = hashMatch[1]
            if (anchorName === "fields") {
              this.handleTabClickEvent(this.$CONSTANTS.ANCHOR_LINKS.FIELDS)
              this.$router.push({
                name  : "issue-field",
                params: {
                  id: +id
                }
              })
            } else if (anchorName === "forms") {
              this.handleTabClickEvent(this.$CONSTANTS.ANCHOR_LINKS.FORMS)
              this.$router.push({
                name  : "issue-form-template",
                params: {
                  id: +id
                }
              })
            }
          } else {
            this.handleTabClickEvent(hash)
          }
        }
      }
    },
    isIssueFieldsAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "997"
          })
        }
      }
    },
    isSortingOrderUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            type      : "success",
            text      : "315",
            parameters: {
              fieldSystemName: this.fieldSystemName
            }
          })
        }
      }
    },
    isIssueFormTemplatesAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "2120"
          })
        }
      }
    }
  }
}